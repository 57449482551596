import React from "react"
import { graphql, PageProps } from "gatsby"
import { useMediaQuery } from "@material-ui/core"
import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { CardWithMaxBenefits } from "../components/CardWithMaxBenefits"
import { FormPKW } from "../components/FormPKW"
import { NewFooter } from "../components/NewFooter"
import { getPageData } from "../helpers/getPageData"
import { PageData } from "../interfaces/pageProps"
import { Banner } from "../components/Banners/Pkw"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const mobile = useMediaQuery("(min-width: 600px)")

  const months = mobile ? `24 месяца рассрочки` : `24 месяца <br/> рассрочки`

  const bannerTitle = `
  <span>Карта «Халва»</span>
  <br/>
  ${months}
  `

  const bannerDescription = `
  — 17% на остаток по карте
  <br />
  — Кэшбэк до 10%
  <br />
  — Обслуживание 0 ₽ 
  `

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader />
      <Banner orderNum="1" description={bannerDescription} title={bannerTitle} />
      <CardWithMaxBenefits orderNum="2" />
      <FormPKW dataLayerName="shortPersonalForm" formBtnText="Оформить карту" orderNum="3" />
      <NewFooter ligal={ligal} orderNum="4" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/media-d/") {
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
